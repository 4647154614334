$(document).ready(function(){

    $(window).on('load', function () {
        //------------------------------------------------------------------------------
        // CTA Slider
        //------------------------------------------------------------------------------

        // after first slider initialization
        $('.slider').on('init', function(event, slick){
            $(".slider .slick-prev").addClass('icon-bubble-red-arrow');
        });

        // before slide changed
        $('.slider').on('beforeChange', function(event, slick){
            $('.slick-arrow').hide();
            $('.slider .img-text').hide();
            $('.slider .cta-label').css('visibility', 'hidden');
        });

        // after slide changed
        $('.slider').on('afterChange', function(event, slick){
            $('.slick-arrow').fadeIn();
            $('.slider .img-text').fadeIn();
            $('.slider .cta-label').css('width', 'auto');
            $('.slider .cta-label').css('visibility', 'visible');
        });

        // slider initialization
        if ($('.slider').length) {
            $('.slider').slick({
                dots: true,
                arrows: true,
                speed: 600,
                slidesToShow: 1.2,
                slidesToScroll: 1,
                responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.1,
                        slidesToScroll: 1,
                    }
                }
                ]
            });
        }

        // next Slide on click
        $(".slide-right").click(function() {
            $('.slider').slick('slickNext');
        });

        // slide on mouseover
        $(".cta-slider-link").on({
            mouseenter: function () {
                $(this).find('.slider-text').after( "<div class='icon-arrow-big-red'></div>");
                $(this).find('.slider-text').hide();
            },
            mouseleave: function () {
                $(this).find('.icon-arrow-big-red').remove();
                $(this).find('.slider-text').show();
            }
        });
    });

    //------------------------------------------------------------------------------
    // Slider/image - gallerie
    //------------------------------------------------------------------------------
    var $slider = $('.gallerie');

    $slider.each(function(i, elem){
        console.log(elem)
        if ($(elem).length) {
            var currentSlide;
            var slidesCount;
            var sliderCounter = document.createElement('div');
            sliderCounter.classList.add('slider-items-counter');
    
            var updateSliderCounter = function(slick, currentIndex) {
                currentSlide = slick.slickCurrentSlide() + 1;
                slidesCount = slick.slideCount;
                $(sliderCounter).text(currentSlide + '/' +slidesCount)
            };
    
            $(elem).on('init', function(event, slick) {
                
                elem.append(sliderCounter);
                updateSliderCounter(slick);
        
                if(slick.slideCount == 1){
                    $(elem).find(".slick-arrow").css("display", "none");
                    $(elem).find(".slider-items-counter").css("display", "none");
                }
                else{
                    $(elem).find(".slick-arrow").css("display", "block");
                    $(elem).find(".slider-items-counter").css("display", "block");
                }
            });
    
            $(elem).on('afterChange', function(event, slick, currentSlide) {
                updateSliderCounter(slick, currentSlide);
            });
    
            $(elem).slick({
                dots: false,
                infinite: true,
                speed: 600,
                slidesToShow: 1
            });
        }
    });

    //------------------------------------------------------------------------------
    // Angebote details - gallerie
    //------------------------------------------------------------------------------
    var $angeboteSlider = $('.angebote-detail-gallerie');

    $angeboteSlider.each(function(i, elem){
        if ($(elem).length) {
            var currentSlide;
            var slidesCount;
            var sliderCounter = document.createElement('div');
            sliderCounter.classList.add('slider-items-counter');

            var updateSliderCounter = function(slick, currentIndex) {
                currentSlide = slick.slickCurrentSlide() + 1;
                slidesCount = slick.slideCount;
                $(sliderCounter).text(currentSlide + '/' +slidesCount)
            };

            $(elem).on('init', function(event, slick) {
                elem.append(sliderCounter);
                updateSliderCounter(slick);

                if(slick.slideCount == 1){
                    $(elem).find(".slick-arrow").css("display", "none");
                    $(elem).find(".slider-items-counter").css("display", "none");
                }
                else{
                    $(elem).find(".slick-arrow").css("display", "block");
                    $(elem).find(".slider-items-counter").css("display", "block");
                }
            });

            $(elem).on('afterChange', function(event, slick, currentSlide) {
                updateSliderCounter(slick, currentSlide);
            });

            $(elem).slick({
                dots: true,
                infinite: true,
                speed: 600,
                slidesToShow: 1
            });
        }
    });


});